import {connect} from 'react-redux';
import Summary from './index';
import {IRootState} from 'app/reducers';
import {AnyAction} from 'redux';
import {IDispatchFromProps, IStateFromProps, ISummaryPair} from './types';
import {ThunkDispatch} from 'redux-thunk';
import {sortBy} from 'lodash';
import {BookingService} from 'app/services/booking/booking.service';
import {IBookingMenuOption} from "app/services/booking/booking.types";
import {BookingActionsNS} from "app/actions/booking/bookingActions";
import {BookingOptionsActionsNS} from "app/actions/bookingOptions/bookingOptionsActions";
import {servicePaymentType} from "shared-types/SharedTypes";
import PhoneNumberService from "shared-services/phone-number-service/index";
import { NavigationActionsNS } from 'app/actions/navigation/navigationActions';
import { enableRobotValidationOnBookingNow } from 'app/components/FooterNav/container';

const NS = 'SummaryContainer';

const mapStateToProps = ({widget}: IRootState): IStateFromProps => {

  const {theme, hideUpsell, activeService, booking, savedBooking} = widget;

  // maps props in customer to human friendly names
  const customerMap: ISummaryPair[] = [
    {order: 0, name: 'firstName', value: 'First Name'},
    {order: 1, name: 'lastName', value: 'Surname'},
    {order: 2, name: 'company', value: 'Company Name'},
    {order: 3, name: 'phone', value: 'Mobile'},
    {order: 4, name: 'email', value: 'Email'},
    {order: 10, name: 'notes', value: 'Requests'},
  ]

  const {viewTime, covers, viewDate, customer, bookedBy, isBookedBy} = booking;

  const details: ISummaryPair[] = customerMap.reduce((a: any, nameObj: ISummaryPair) => {
    const value: string = (customer as any)[nameObj.name];
    if (value) {
      let _value = value;
      if(nameObj.name === 'phone') {
        _value = PhoneNumberService.formatInterNationalPhoneNumber(value, (customer as any)['country']);
      }
      a.push({
        name: nameObj.value,
        value: _value,
        order: nameObj.order
      })
    }
    return a;
  }, [
    {order: 5, name: 'Date', value: viewDate},
    {order: 6, name: 'For', value: covers.toString()},
    {order: 7, name: 'At', value: viewTime},
  ]);

  const isEditMode = !!savedBooking;

  const paymentDetails = widget.activeService ? widget.activeService.paymentDetails : null;

  const isUpsellOptions = widget.booking.selectedMenuOptions.some(t => t.isUpsellItem);

  const showPaymentDetails: boolean = BookingService.isPaymentDetailsVisible(widget.booking.covers, paymentDetails, isUpsellOptions);

  let upsellOptions = activeService.paymentDetails && activeService.paymentDetails.optionsUpsell ? activeService.paymentDetails.optionsUpsell : [];

  if (isEditMode) {
    if (widget.activeVenue.widgetSettings.enableAdvancedEditingSupport) {
      //in AES mode we alow editing of upsell options with preauth payment.
      upsellOptions = upsellOptions.filter(option => option.paymentType === servicePaymentType.noPayment || option.paymentType == servicePaymentType.preAuth);
    }
    else {
      upsellOptions = upsellOptions.filter(option => option.paymentType === servicePaymentType.noPayment); // If its edit booking then remove the upsell options that has payment on them
    }
  }

  const upsellHeading = activeService.paymentDetails && activeService.paymentDetails.upsellHeading ? activeService.paymentDetails.upsellHeading : '';
  const upsellDescription = activeService.paymentDetails && activeService.paymentDetails.upsellDescription ? activeService.paymentDetails.upsellDescription : '';
  let selectedUpsellOptions: IBookingMenuOption[] = [];
  if (upsellOptions) {
    selectedUpsellOptions = BookingService.getBookingOptions(
      upsellOptions, booking.selectedUpsellOptions, covers, false, true);
  }


  const selectionData = BookingService.getSelectionData(selectedUpsellOptions, upsellHeading, upsellDescription);

  return {
    wrapperStyle: widget.wrapperStyle,
    details: sortBy(details, 'order'),
    tags: widget.booking ? widget.booking.tags : null,
    showPaymentDetails,
    upsellMenuOptions: upsellOptions,
    selectionData: selectionData,
    theme,
    hideUpsell,
    isEditMode,
    bookedBy,
    isBookedBy,
    showRecaptcha: enableRobotValidationOnBookingNow(widget),
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    handleDataForUpsell: async (opts: IBookingMenuOption[]) => {
      opts.forEach((val: IBookingMenuOption) => {
        val.isUpsellItem = true;
        return val;
      });
      await dispatch(BookingOptionsActionsNS.selectedMenuOptions(opts, true));
      await dispatch(BookingActionsNS.updatePaymentBasedOnUpsell());
    },
    handleRecaptcha: () => {
      dispatch(NavigationActionsNS.passRobotCheck());
    },
  }
};

const SummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Summary as any);

export default SummaryContainer;
