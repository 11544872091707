import {connect} from 'react-redux';
import ThankYou from './index';
import {IRootState} from 'app/reducers';
import {AnyAction} from 'redux';
import {IDispatchFromProps, IStateFromProps} from './types';
import {ThunkDispatch} from 'redux-thunk';
import {MessageService} from 'app/services/message/message.service';
import {IVenue} from 'app/models';

const NS = 'ThankYouContainer';

const mapStateToProps = ({widget}: IRootState): IStateFromProps => {
  const {wrapperStyle, theme, standbyData, activeVenue} = widget;
  return {
    wrapperStyle,
    theme,
    message:
      standbyData
        ? `We've added you to the standby list for ${standbyData.viewTime ? standbyData.viewTime : 'any time'} on ${standbyData.viewDate} and sent you an email with the details.`
        : MessageService.get('finalWidgetScreenMessage', activeVenue as IVenue)
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {}
};

const ThankYouContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ThankYou as any);

export default ThankYouContainer;
